import React, { useEffect, useState } from "react";
import './styles.scss';
import { DatePicker, Form, Input, Modal, Select } from "antd";
import { LotByProductionFormProps } from "./types";
import esES from 'antd/es/date-picker/locale/es_ES'
import dayjs from 'dayjs';
import { useLotByProduction } from "../../../Services/useLotByProduction";
import { useGetLot } from "../../../Services/useGetLot";
import { useGetCampaigns } from "../../../Services/useGetCampaigns";
import moment from "moment";
import { useGetHybrid } from "../../../Services/useGetHybrid";
import { useGetSowRelation } from "../../../Services/useGetSowRelation";
import { LotByProductionProps } from "../../../Services/useGetLotByProduction";
import { DataType } from "../../Sections/LotByProduction";

export interface SelectValues{
    value: string,
    id: string,
    key: number,
  }
export interface LotsFormFilters {
    id: number
    code: string,
    field_id: number, 
    ha: number,
    contract_start_date: Date,
    contract_end_date: Date
}

export interface LotFormFields{
    code: string,
    field_id: number, 
    ha: number,
    contract_start_date: Date,
    contract_end_date: Date
}

export const LotByProductionForm: React.FC<LotByProductionFormProps> =({
    form,
    rowSelect,
    showForm,
    callback,
    handleCancelForm,
    setDataTable,
    form_filter_campaign,
    setHasTotals
})=>{

    useEffect(()=>{
        form.resetFields()
    },[])
    
    const { data: lots } = useGetLot()
    const { data: hybrid} = useGetHybrid()
    const { data: campaigns } = useGetCampaigns()
    const { data: sowRelation } = useGetSowRelation()
    const { createLotByProduction, editLotByProduction} = useLotByProduction()
    const { Option } = Select;
    const dateFormat = 'YYYY/MM/DD';
    
    const [loadingBtn, setLoadingBtn] = useState(false)  
     
    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
        callback()
    }

    const createNewData = (data: LotByProductionProps) =>{
        let addData: DataType = {
            index: 0,  
            id:  data.id,            
            key: data.id,
            lot_code: data.lot.code,
            ha: data.lot.hectares.toString(),
            farm_code: data.lot.farm.code,
            farm_id: data.lot.farm.id.toString(),
            zone_code: data.lot.farm.city.zone.code,
            zone_id:  data.lot.farm.city.zone.id.toString(),
            lot_id:  data.lot.id.toString(),
            campaign_code: data.campaign.code,
            campaign_id:  data.campaign.id.toString(),
            hybrid_code:  data.hybrid.code,
            hybrid_id:  data.hybrid.id, 
            is_full_grower: data.lot.farm.is_full_grower_farm ? 'true': 'false',
            tentative_sow_date:  data.tentative_sow_date,
            sow_date:  data.sow_date,              
            male_1_sow_date: data.male_1_sow_date,
            male_2_sow_date: data.male_2_sow_date, 
            male_3_sow_date: data.male_3_sow_date, 
            harvest_date:  data.harvest_date,
            detasseled_date:  data.detasseled_date,            
            cut_date: data.cut_date,
            female_sow_gdus:  data.female_sow_gdus,
            male_1_sow_gdus:  data.male_1_sow_gdus,
            male_2_sow_gdus:  data.male_2_sow_gdus,
            male_3_sow_gdus: data.male_3_sow_gdus,
            observations:  data.observations,
            sow_relation_code: data.sow_relation?.code ? data.sow_relation.code : '',
            sow_relation_id: data.sow_relation?.id? data.sow_relation.id.toString() : ''
        }
        return addData
    }

    const handleSave = async(values: {
        campaign: SelectValues;
        lot: SelectValues;
        hybrid: SelectValues;
        tentative_sow_date: {$d: string}  ;
        sow_date: {$d: string} | undefined ;
        male_1_sow_date: {$d: string} | undefined ;
        male_2_sow_date: {$d: string} | undefined ;        
        male_3_sow_date: {$d: string} | undefined ;
        harvest_date: {$d: string} | undefined;
        detasseled_date: {$d: string} | undefined;
        cut_date: {$d: string} | undefined;
        female_sow_gdus: number;
        male_1_sow_gdus: number;
        male_2_sow_gdus: number;
        male_3_sow_gdus: number;
        sow_relation: SelectValues;
        observations: string;
    })=>{
        //No eliminar todavía este comentario, posiblemente después sea obligatorio colocar la fecha
        // if(!values.sow_date && !values.tentative_sow_date ){
        //     notification.warning({
        //         message: 'Por favor, seleccione una fecha de siembra o una fecha tentativa de siembra',
        //         placement: 'topRight'
        //     })
        // }else{     
        setLoadingBtn(true)
        let newData = {
            campaign: Number(values.campaign.key),
            lot: Number(values.lot.key),
            hybrid: Number(values.hybrid.key),            
            female_sow_gdus: Number(values.female_sow_gdus),
            male_1_sow_gdus: Number(values.male_1_sow_gdus),
            male_2_sow_gdus: Number(values.male_2_sow_gdus),            
            male_3_sow_gdus: Number(values.male_3_sow_gdus),
            observations: values.observations,
            tentative_sow_date: values?.tentative_sow_date?.$d ? moment(values.tentative_sow_date.$d).format('YYYY-MM-DD') : null,
            sow_date: values.sow_date?.$d ? moment(values.sow_date.$d).format('YYYY-MM-DD') : null,
            male_1_sow_date: values.male_1_sow_date?.$d ? moment(values.male_1_sow_date.$d).format('YYYY-MM-DD') : null,
            male_2_sow_date: values.male_2_sow_date?.$d ? moment(values.male_2_sow_date.$d).format('YYYY-MM-DD') : null,
            male_3_sow_date: values.male_3_sow_date?.$d ? moment(values.male_3_sow_date.$d).format('YYYY-MM-DD') : null,
            harvest_date: values.harvest_date?.$d ? moment(values.harvest_date.$d).format('YYYY-MM-DD') : null,
            detasseled_date: values.detasseled_date?.$d ?  moment(values.detasseled_date.$d).format('YYYY-MM-DD') : null,
            cut_date: values.cut_date?.$d ?  moment(values.cut_date.$d).format('YYYY-MM-DD') : null,
            sow_relation: values.sow_relation?.key ? Number(values.sow_relation.key) : undefined
        }
        if(rowSelect && rowSelect.id){
             //ver si cambia el valor de hasTotals
            const data = await editLotByProduction(handleCancel, rowSelect.id, newData)     
            if (data) {
                let addData = createNewData(data)    
                setDataTable((prev: DataType[]) => 
                    prev.map((item) => 
                        item.id === addData.id ? addData : item
                    )
                );
                if( rowSelect.ha !== addData.ha){
                    setHasTotals((prevTotal: string) => {
                        const totals = parseInt(prevTotal.replace(/\./g, ''), 10) - parseInt(rowSelect.ha) + parseInt( addData.ha);
                        return totals.toLocaleString('es-ES');
                        });                    
                }
            }                   
        }else{
            const data = await createLotByProduction(handleCancel, newData)            
            if(data){
                let addData = createNewData(data)              
                let campaign = form_filter_campaign.getFieldValue('campaign')
                //ver si cambia el valor de hasTotals
                if(campaign.label == addData.campaign_code ){
                    setDataTable((prev: DataType[])=>{
                        let index = prev.length
                        addData.index = index
                        return[addData,...prev]
                    })
                }  
                setHasTotals((prevTotal: string) => {
                    const totals = parseInt(prevTotal.replace(/\./g, ''), 10)  + parseInt( addData.ha);
                    return totals.toLocaleString('es-ES');
                });               
            }
        }             
    }        

    return(
        <div>
            <Modal
                title={rowSelect ? 'Editar Lote' : 'Nuevo Lote'}
                open={showForm}
                onCancel={handleCancel} 
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form.submit()}
            >
                <Form
                    form={form}
                    id='form'
                    onFinish={handleSave}
                    layout="vertical"                    
                >
                    <Form.Item
                        label='Lote'
                        name='lot'
                        initialValue={rowSelect ? {
                            value: rowSelect?.lot_code,
                            label: rowSelect?.lot_code ,
                            key: rowSelect?.lot_id
                        }: null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        validateTrigger={['onChange', 'onBlur']}
                    >
                        <Select                           
                            labelInValue  
                            loading={!lots || lots.length === 0}
                            placeholder={!lots || lots.length === 0 ? 'Cargando lotes...' : 'Seleciona un lote'}    
                            showSearch               
                        >
                            {lots && lots.map((item) => (
                                <Option value={item.detailed_code} key={item.id} >
                                {item.detailed_code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>   
                    <Form.Item
                        label='Campaña'
                        name='campaign'
                        initialValue={rowSelect ?{
                            value: rowSelect?.campaign_code,
                            label: rowSelect?.campaign_code,
                            key: rowSelect?.campaign_id
                        } : null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                         <Select                        
                            labelInValue    
                            loading={!campaigns || campaigns.length === 0}
                            placeholder={!campaigns || campaigns.length === 0 ? 'Cargando campañas...' : 'Seleciona una campaña'} 
                            showSearch                        
                        >
                            {campaigns && campaigns.map((item) => (
                                <Option value={item.code} key={item.id}>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>   
                    <Form.Item
                        label='Híbrido'
                        name='hybrid'
                        initialValue={rowSelect ? {
                            value: rowSelect?.hybrid_code,
                            label: rowSelect?.hybrid_code,
                            key: rowSelect?.hybrid_id
                        } : null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                         <Select                        
                            labelInValue     
                            loading={!hybrid || hybrid.length === 0}
                            placeholder={!hybrid || hybrid.length === 0 ? 'Cargando híbridos...' : 'Seleciona un híbrido'}    
                            showSearch                    
                        >
                            {hybrid && hybrid.map((item) => (
                                <Option value={item.code} key={item.id} placeholder='híbrido'>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item> 
                    <Form.Item
                        label='Fecha tentativa de siembra'
                        name='tentative_sow_date'
                        initialValue={rowSelect?.tentative_sow_date ? dayjs(rowSelect.tentative_sow_date, dateFormat) : null}                                           
                    >
                        <DatePicker
                        defaultValue={rowSelect?.tentative_sow_date ? dayjs(rowSelect.tentative_sow_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>   
                    <Form.Item
                        label='Fecha de siembra hembra'
                        name='sow_date'
                        initialValue={rowSelect?.sow_date ? dayjs(rowSelect.sow_date, dateFormat) : null}                        
                       
                    >
                        <DatePicker
                            defaultValue={rowSelect?.sow_date ? dayjs(rowSelect.sow_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>     
                    <Form.Item
                        label='Fecha de siembra Macho 1'
                        name='male_1_sow_date'
                        initialValue={rowSelect?.male_1_sow_date ? dayjs(rowSelect.male_1_sow_date, dateFormat) : null}                        
                       
                    >
                        <DatePicker
                            defaultValue={rowSelect?.male_1_sow_date ? dayjs(rowSelect.male_1_sow_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>   
                    <Form.Item
                        label='Fecha de siembra Macho 2'
                        name='male_2_sow_date'
                        initialValue={rowSelect?.male_2_sow_date ? dayjs(rowSelect.male_2_sow_date, dateFormat) : null}                                               
                    >
                        <DatePicker
                            defaultValue={rowSelect?.male_2_sow_date ? dayjs(rowSelect.male_2_sow_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>
                    <Form.Item
                        label='Fecha de siembra Macho 3'
                        name='male_3_sow_date'
                        initialValue={rowSelect?.male_3_sow_date ? dayjs(rowSelect.male_3_sow_date, dateFormat) : null}                                               
                    >
                        <DatePicker
                            defaultValue={rowSelect?.male_3_sow_date ? dayjs(rowSelect.male_3_sow_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>                  
                    <Form.Item
                        label='Fecha de cutter'
                        name='detasseled_date'
                        initialValue={rowSelect?.detasseled_date ? dayjs(rowSelect.detasseled_date, dateFormat) : null}                        
                       
                    >
                        <DatePicker
                            defaultValue={rowSelect?.detasseled_date ? dayjs(rowSelect.detasseled_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>
                    <Form.Item
                        label='Fecha de Puller'
                        name='cut_date'
                        initialValue={rowSelect?.cut_date ? dayjs(rowSelect.cut_date, dateFormat) : null}                        
                       
                    >
                        <DatePicker
                            defaultValue={rowSelect?.cut_date ? dayjs(rowSelect.cut_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>
                    <Form.Item
                        label='Fecha de cosecha'
                        name='harvest_date'
                        initialValue={rowSelect?.harvest_date ? dayjs(rowSelect.harvest_date, dateFormat) : null}                        
                       
                    >
                        <DatePicker
                            defaultValue={rowSelect?.harvest_date ? dayjs(rowSelect.harvest_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>
                    <Form.Item
                        label='GDU de siembra Hembra'
                        name='female_sow_gdus'
                        initialValue={rowSelect?.female_sow_gdus || 0}   
                         rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}                     
                       
                    >
                        <Input      
                            type="number"        
                            placeholder="number"
                        />
                    </Form.Item>
                    <Form.Item
                        label='GDU de siembra Macho 1'
                        name='male_1_sow_gdus'
                        initialValue={rowSelect?.male_1_sow_gdus || 0} 
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}                       
                       
                    >
                        <Input      
                            type="number"        
                            placeholder="number"
                        />
                    </Form.Item>
                    <Form.Item
                        label='GDU de siembra Macho 2'
                        name='male_2_sow_gdus'
                        initialValue={rowSelect?.male_2_sow_gdus || 0}  
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}                                            
                    >
                        <Input      
                            type="number"        
                            placeholder="number"
                        />
                    </Form.Item> 
                    <Form.Item
                        label='GDU de siembra Macho 3'
                        name='male_3_sow_gdus'
                        initialValue={rowSelect?.male_3_sow_gdus || 0}  
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}                                            
                    >
                        <Input      
                            type="number"        
                            placeholder="number"
                        />
                    </Form.Item> 
                    <Form.Item
                        label='Relación de siembra'
                        name='sow_relation'
                        initialValue={rowSelect ? {
                            value: rowSelect?.sow_relation_code,
                            label: rowSelect?.sow_relation_code,
                            key: rowSelect?.sow_relation_id
                        } : null}
                    >
                         <Select                        
                            labelInValue    
                            placeholder='Selecciona una relación'                        
                        >
                            {sowRelation && sowRelation.map((item) => (
                                <Option value={item.code} key={item.id}>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item> 
                    <Form.Item
                        label='Observaciones'
                        name='observations'
                        initialValue={rowSelect?.observations}                                               
                    >
                        <Input             
                            placeholder="Comentario"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default LotByProductionForm;