import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './styles.scss'
import { Button, Col, Form, Modal, Row, Select, Table, Tooltip } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    CheckOutlined,
    CloseOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { LotByProductionProps, useGetLotByProduction } from '../../../Services/useGetLotByProduction';
import {  useLotByProduction } from '../../../Services/useLotByProduction';
import LotByProductionForm from '../../Forms/LotByProductionForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { useGetCampaigns } from '../../../Services/useGetCampaigns';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { 
  END_POINT_LOTS_BY_PRODUCTION_EXPORT_DATA,
  END_POINT_LOTS_BY_PRODUCTION_EXPORT_LOTS
} from '../../../Axios/ENDPOINTS';
import { useDownloadExcel } from '../../../Services/useDownloadExcel';
import { UserContext } from '../../../Contexts/UserContext/UsersContext';

export interface DataType {
    index: number;    
    id: number;
    key: number;
    lot_code: string;
    lot_id: string;
    ha: string;
    farm_code: string,
    farm_id: string
    zone_code: string;
    zone_id: string;
    campaign_code: string;
    campaign_id: string;
    hybrid_code: string,
    hybrid_id: number, 
    is_full_grower: string,
    tentative_sow_date: string;
    sow_date: string;
    male_1_sow_date: string;
    male_2_sow_date: string;      
    male_3_sow_date: string;  
    harvest_date: string;
    detasseled_date: string;    
    cut_date: string;
    female_sow_gdus: number;
    male_1_sow_gdus: number;
    male_2_sow_gdus: number;
    male_3_sow_gdus: number;
    observations: string;
    sow_relation_code?: string;
    sow_relation_id?: string;
}

const LotByProduction: React.FC = () => {

    const {data: dataApi} = useGetLotByProduction()    
    const {deleteLotByProduction, getLotByProduction} = useLotByProduction()
    const {adjustedTableHeigthScroll} = useContext(StylesContext)
    const { data: campaign } = useGetCampaigns()
    const { Option } = Select;
    const [form_filter_campaign] = useForm()
    const {downloadEXCEL} = useDownloadExcel()
    const {userData} = useContext(UserContext)

    const [form_lot_by_production] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(true)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [rowSelect, setRowSelect] = useState<DataType | undefined>()
    const [hasTotals, setHasTotals] = useState<string>()    
    const [loadingExcel, setLoadingExcel] = useState<boolean>(false) 
    const [loadingExcelColumns, setLoadingExcelcolumns] = useState<boolean>(false) 

    const filters = useCallback((type: 'zone' | 'farm' | 'lot' | 'full_grower' | 'hybrid') => {
      console.log('filters')
      let textAndValue: { text: string, value: string }[] = [];
      let uniqueValues = new Set();
  
      dataApi?.forEach((item) => {
          let code
          
          switch(type){
            case 'zone':
              code =  item.lot.farm.city.zone.code 
              break;
            case 'farm':
              code = item.lot.farm.code
              break;
            case 'lot':
              code = item.lot.code
              break;
            case 'full_grower':
              code = item.lot.farm.is_full_grower_farm.toString()
              break;
            case 'hybrid':
              code = item.hybrid.code
              break;                  
          }
  
          if (!uniqueValues.has(code)) {
              uniqueValues.add(code);
              textAndValue.push({
                  text: type == 'full_grower' ? code == 'true' ? 'Es full Grower' : 'No es full grower' : code,
                  value: code
              });
          }
      });
      return textAndValue;
    },[dataApi])

    const handleRenderer = useCallback( (text: string, data: DataType) =>{
      console.log('handleRenderer')
      return (
        <div className='zone_action_action_render'>
            <EditOutlined 
              className='icons'
              onClick={()=>{
                    setShowForm(true) 
                    setRowSelect(data)
              }}
            />
            <DeleteOutlined               
              className='icons'
              onClick={()=>{
                setRowSelect(data)
                setShowConfirmDelete(true)
              }}
              style={{marginLeft:'30px'}} />
        </div>  
      )      
    },[])

    const columns: ColumnsType<DataType> = useMemo(() => {
      const baseColumns: ColumnsType<DataType> = [
      {
        title: 'Campaña',
        dataIndex: 'campaign_code',
        key: '1',
        width: 110,
        rowScope:  'row', 
      },
      {
        title: 'Zona',
        dataIndex: 'zone_code',
        key: '2',
        width: 100,
        filters: filters('zone'),
        onFilter: (value, record) => typeof value === 'string' && record.zone_code == value,
        filterSearch: true,
        fixed: 'left',
        sorter: (a, b) => a.zone_code.localeCompare(b.zone_code),
      },
      {
        title: 'Campo',
        dataIndex: 'farm_code',
        key: '3',
        width: 140,
        filters: filters('farm'),
        onFilter: (value, record) => typeof value === 'string' && record.farm_code == value,
        filterSearch: true,
        rowScope:  'row',       
        fixed: 'left',
        sorter: (a, b) => a.farm_code.localeCompare(b.farm_code),
      },
      {
        title: 'Lote',
        dataIndex: 'lot_code',
        className:'columns_lots',
        key: '4',
        width: 130,
        filters: filters('lot'),
        onFilter: (value, record) => typeof value === 'string' && record.lot_code == value,
        filterSearch: true,      
        fixed: 'left',
        sorter: (a, b) => a.lot_code.localeCompare(b.lot_code),
      },
      {
        title: 'Ha',
        dataIndex: 'ha',
        key: '5',
        width: 90,
        rowScope:  'row', 
        sorter: (a, b) => Number(a.ha) - Number(b.ha),                         
      },   
      {
          title: 'Híbrido',
          dataIndex: 'hybrid_code',
          key: '6',
          width: 160,
          filters: filters('hybrid'),
          onFilter: (value, record) => typeof value === 'string' && record.hybrid_code == value,
          filterSearch: true,
          sorter: (a, b) => a.hybrid_code.localeCompare(b.hybrid_code),
      },
      {
        title: 'Full Grower',
        dataIndex: 'is_full_grower',      
        render: (text)=><div>{text == 'true' ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</div>,
        key: '7',
        width: 110,
        filters: filters('full_grower'),
        onFilter: (value, record) => typeof value === 'string' && record.is_full_grower.toString() == value, 
        rowScope:  'row', 
      },
      {
          title: 'F Tent. de Incio de Siembra',
          dataIndex: 'tentative_sow_date',
          key: '8',
          sorter: (a, b) => new Date(a.tentative_sow_date).getTime() - new Date(b.tentative_sow_date).getTime(),
          
      },
      {
          title: 'FS H',
          dataIndex: 'sow_date',
          key: '9',   
          rowScope:  'row',  
          sorter: (a, b) => new Date(a.sow_date).getTime() - new Date(b.sow_date).getTime(),    
      },   
      {
        title: 'FS M1',
        dataIndex: 'male_1_sow_date',
        key: '10',   
        rowScope:  'row',  
        sorter: (a, b) => new Date(a.male_1_sow_date).getTime() - new Date(b.male_1_sow_date).getTime(),    
      },  
      {
        title: 'FS M2',
        dataIndex: 'male_2_sow_date',
        key: '11',   
        rowScope:  'row',  
        sorter: (a, b) => new Date(a.male_2_sow_date).getTime() - new Date(b.male_2_sow_date).getTime(),    
      }, 
      {
        title: 'FS M3',
        dataIndex: 'male_3_sow_date',
        key: '12',   
        rowScope:  'row',  
        sorter: (a, b) => new Date(a.male_3_sow_date).getTime() - new Date(b.male_3_sow_date).getTime(),    
      },   
      {
          title: 'Fecha de cutter',
          dataIndex: 'detasseled_date',
          key: '13',  
          sorter: (a, b) => new Date(a.detasseled_date).getTime() - new Date(b.detasseled_date).getTime(),
      }, 
      {
        title: 'Fecha de Puller',
        dataIndex: 'cut_date',
        key: '14',  
        sorter: (a, b) => new Date(a.cut_date).getTime() - new Date(b.cut_date).getTime(),
    },      
      {
          title: 'Fecha de cosecha',
          dataIndex: 'harvest_date',
          key: '15',
          rowScope:  'row', 
          sorter: (a, b) => new Date(a.harvest_date).getTime() - new Date(b.harvest_date).getTime(),
      },  
      {   
          title: 'GDU de siembra Hembra',
          dataIndex: 'female_sow_gdus',
          key: '16', 
          sorter: (a, b) => Number(a.female_sow_gdus) - Number(b.female_sow_gdus),      
      }, 
      {   
          title: 'GDU de siembra Macho 1 ',
          dataIndex: 'male_1_sow_gdus',
          key: '17',     
          rowScope:  'row',  
          sorter: (a, b) => Number(a.male_1_sow_gdus) - Number(b.male_1_sow_gdus), 
      }, 
      {   
          title: 'GDU de siembra Macho 2',
          dataIndex: 'male_2_sow_gdus',
          key: '18', 
          sorter: (a, b) => Number(a.male_2_sow_gdus) - Number(b.male_2_sow_gdus),       
      }, 
      {   
        title: 'GDU de siembra Macho 3',
        dataIndex: 'male_3_sow_gdus',
        key: '19',      
        rowScope:  'row',   
        sorter: (a, b) => Number(a.male_3_sow_gdus) - Number(b.male_3_sow_gdus),
      }, 
      {   
          title: 'Relación de siembra',
          dataIndex: 'sow_relation_code',
          key: '20',        
      }, 
      {   
          title: 'Observaciones',
          dataIndex: 'observations',
          key: '21',
          ellipsis: true,        
          rowScope:  'row',         
      },       
    ];
    
    if (userData?.is_staff) {
      baseColumns.push({
        title: 'Acciones',
        dataIndex: 'id',
        render: handleRenderer,
        key: '22',
        width: 110,
        fixed: 'right',
      });
    }

    return baseColumns;
  }, [userData, filters, handleRenderer]);

    useEffect(()=>{
      if(campaign){
        let campaignId = campaign[campaign.length -1]
        form_filter_campaign.setFieldValue('campaign',{
          label: campaignId.code,
          value: campaignId.code,
          key: campaignId.id.toString(),
        })
       
        handleShearch(
          {
            label: campaignId.code,
            value: campaignId.code,
            key: campaignId.id.toString(),
          }, true)      
      }      
    },[campaign])   

    const handleShearch = async (
      campaign:{
          label: string,
          value: string,
          key: string,
      },
      loading?: boolean,
    ) =>{
        !loading && setLoadingTableData(true)
        const data = await getLotByProduction(campaign.key, callback)
        if(data){
          rowData(data)
        }
    }
  

    const rowData = (data? : LotByProductionProps[]) =>{
      console.log('rowData')
        let filterData: DataType[]= []
        let list: LotByProductionProps[] = data ? data : dataApi
        let totals = 0
        if(list && list){
          list.map((data, index)=>{    
            totals= totals + data.lot.hectares                    
            filterData.push({
              index: index,  
              id:  data.id,              
              key: data.id,
              lot_code: data.lot.code,
              ha: data.lot.hectares.toString(),
              farm_code: data.lot.farm.code,
              farm_id: data.lot.farm.id.toString(),
              zone_code: data.lot.farm.city.zone.code,
              zone_id:  data.lot.farm.city.zone.id.toString(),
              lot_id:  data.lot.id.toString(),
              campaign_code: data.campaign.code,
              campaign_id:  data.campaign.id.toString(),
              hybrid_code:  data.hybrid.code,
              hybrid_id:  data.hybrid.id, 
              is_full_grower: data.lot.farm.is_full_grower_farm ? 'true': 'false',
              tentative_sow_date:  data.tentative_sow_date,
              sow_date:  data.sow_date,              
              male_1_sow_date: data.male_1_sow_date,
              male_2_sow_date: data.male_2_sow_date, 
              male_3_sow_date: data.male_3_sow_date, 
              harvest_date:  data.harvest_date,
              detasseled_date:  data.detasseled_date,
              female_sow_gdus:  data.female_sow_gdus,
              male_1_sow_gdus:  data.male_1_sow_gdus,
              male_2_sow_gdus:  data.male_2_sow_gdus,
              male_3_sow_gdus: data.male_3_sow_gdus,
              observations:  data.observations,
              sow_relation_code: data.sow_relation?.code ? data.sow_relation.code : '',
              sow_relation_id: data.sow_relation?.id? data.sow_relation.id.toString() : '',
              cut_date: data.cut_date
            })
          })      
        }     
        setHasTotals(totals.toLocaleString('es-ES'))  
        setDataTable(filterData)
        setLoadingTableData(false)
    }


    const callback = () =>{
        setShowForm(false)
        setLoadingTableData(false)
        setShowConfirmDelete(false)   
    }




  const deleteLot = async ()=>{
    console.log('delete')
    if(rowSelect && rowSelect.id){
      const status = await deleteLotByProduction(callback, rowSelect.id)
      if(status && status == 200){
        setDataTable((prev: DataType[]) => 
          prev.filter((item) => item.id !== rowSelect.id)
        );
        if (hasTotals) {
          const totals = parseInt(hasTotals.replace(/\./g, ''), 10) - parseInt(rowSelect.ha.replace(/\./g, ''), 10);
          setHasTotals(totals.toLocaleString('es-ES'));
        }      
      }
    }
  }

  const ModalConfirmDelete = () => {
    console.log('ModalConfirmDelete')
    return(
        <Modal
            title="Eliminar"
            open={showConfirmDelete}
            onOk={deleteLot}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Estás seguro de que desea eliminar {rowSelect?.lot_code}?</p>
        </Modal>
    )
  }

  

  const handleCancelForm = ()=>{
    console.log('handleCancelForm')
    form_lot_by_production.resetFields() 
    setRowSelect(undefined)        
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: {currentDataSource:DataType[]}) => {
    console.log('handleTableChange')
    let total = 0
    
    extra.currentDataSource.forEach((row: DataType)=>{
      total = total + Number(row.ha)
    })
    setHasTotals(total.toLocaleString('es-ES'))

  };


  return( 
    <>
      <ModalConfirmDelete/>
      {showForm && <LotByProductionForm
          form = {form_lot_by_production}
          rowSelect = {rowSelect}
          showForm={showForm}
          callback={callback}
          handleCancelForm = {handleCancelForm}
          setDataTable={setDataTable}
          form_filter_campaign={form_filter_campaign}
          setHasTotals={setHasTotals}
      />}      
      <div className='lotByProduction_section_container'>                                  
          <Row
            className='bodyContainer_title'
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
          >
            <div>
              <h2>Lotes en producción</h2>
            </div>
            <div className='lotByProduction_section_col'>
              <h3 className='h3_title'>Total hectáreas:</h3>
              <h3 className='h3_data'>{hasTotals} has</h3>
            </div>              
          </Row> 
          <Row
              style={{
                  width: '100%',
                  padding:'20px 0px',
                  //margin: '10px 0px',
                  display: 'flex',
                  justifyContent: 'space-between'

              }}
          >
            <Col
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: '10px',
                }}
            >
              <Form
                  form={form_filter_campaign}
                  id='form_filter_campaign'
                  onFinish={(values)=> handleShearch(values.campaign)}
                  layout='inline'
                  style={{ margin:'7px 0px 0px'}}
              >
                <Form.Item
                  name='campaign'
                  rules={[
                      {
                          required: true,
                          message:'Este campo es obligatorio'
                      }
                  ]}
                >
                  <Select
                    labelInValue
                    loading={!campaign || campaign.length === 0}
                    placeholder={!campaign || campaign.length === 0 ? 'Cargando campañas...' : 'Campaña'} 
                  > 
                    {campaign && campaign.map((item) => (
                        <Option value={item.code} key={item.id}>
                            {item.code}
                        </Option>
                    ))}
                  </Select>
                </Form.Item>                                  
                <Form.Item>
                  <Button
                      type='primary'
                      htmlType='submit'
                      
                  >
                      Buscar
                  </Button>
                  <Tooltip
                      title= 'Selecciona una campaña para ver los lotes en producción'
                      placement='right'
                  >
                      <ExclamationCircleOutlined style={{
                          color:'orange',
                          cursor: 'pointer',
                          marginLeft: '10px'}}/>
                  </Tooltip>
                </Form.Item>
              </Form>
              {
                userData?.is_staff &&
                  <Button
                    type='primary'                    
                    onClick={()=>{
                            setShowForm(true)
                            setRowSelect(undefined)
                        }}
                    >   
                      Nuevo lote
                  </Button> 
              }               
            </Col>
            <Col
            >
              <Button 
                disabled={loadingExcel || !form_filter_campaign.getFieldValue('campaign')}
                loading={loadingExcel}
                style={{marginRight: '10px'}}
                onClick={()=>{
                    setLoadingExcel(true)
                    let campaign = form_filter_campaign.getFieldValue('campaign')
                    if(campaign){
                        downloadEXCEL(campaign.key, setLoadingExcel, END_POINT_LOTS_BY_PRODUCTION_EXPORT_DATA, `Lotes en producción - ${campaign.label}`) 
                    }
                }} 
                type="primary" 
              >
                Exportar excel
              </Button>
              <Button 
                disabled={loadingExcelColumns || !form_filter_campaign.getFieldValue('campaign')}
                loading={loadingExcelColumns}
                onClick={()=>{
                  setLoadingExcelcolumns(true)
                    let campaign = form_filter_campaign.getFieldValue('campaign')
                    if(campaign){
                        downloadEXCEL(campaign.key, setLoadingExcelcolumns, END_POINT_LOTS_BY_PRODUCTION_EXPORT_LOTS, `Exportar columnas - ${campaign.label}`) 
                    }
                }} 
                type="primary" 
              >
                Exportar columnas
              </Button>
            </Col>
          </Row>        
          <Row 
            className='bodyContent_container_table'
            >
              {
                loadingTableData 
                ? <SpinerHorizontal/> 
                : <Table 
                    style={{
                        width: '98%',
                    }}
                    columns={columns} 
                    dataSource={dataTable} 
                    onChange={handleTableChange} 
                    pagination={false}
                    scroll={{ x: 3200, y: adjustedTableHeigthScroll }}
                  />    
                } 
          </Row>             
      </div>
    </>                
  )
};

export default LotByProduction;